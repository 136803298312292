<template>
  <base-modal :id="id">
    <h4>Deactivate Employee</h4>
    <p>Are you sure you want to deactivate this Employee?</p>
    <table class="no-zebra">
      <tbody>
        <tr>
          <td>
            <strong> {{ employee.firstName }} {{ employee.lastName }} </strong>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="button-group">
      <a class="button warning" @click="deactivate()">Deactivate</a>
      <a class="button secondary" @click="cancel()">Cancel</a>
    </div>
  </base-modal>
</template>
<script>
import BaseModal from "@/components/modals/BaseModal";
import micromodal from "micromodal";
export default {
  name: "DeactivateEmployeeModal",
  components: { BaseModal },
  props: {
    employee: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data: () => {
    return {
      id: "modal-deactivate-employee",
    };
  },
  methods: {
    deactivate() {
      this.$emit("deactivateEmployee", this.employee.id);
    },
    cancel() {
      micromodal.close(this.id);
    },
  },
};
</script>
