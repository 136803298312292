<template>
  <base-modal :id="id">
    <h4>Dlete Employee Location</h4>
    <p>Are you sure you want to remove this Location for the employee?</p>
    <table class="no-zebra">
      <tbody>
        <tr>
          <td>
            <strong> {{location.name}} </strong>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="button-group">
      <a class="button warning" @click="remove()">Remove</a>
      <a class="button secondary" @click="cancel()">Cancel</a>
    </div>
  </base-modal>
</template>
<script>
import BaseModal from "@/components/modals/BaseModal";
import micromodal from "micromodal";
export default {
  name: "DeleteEmployeeLocationModal",
  components: { BaseModal },
  props: {
    location: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data: () => {
    return {
      id: "modal-employee-delete-location",
    };
  },

  methods: {
    remove() {
      this.$emit("removeLocation", this.location.locationId);
    },
    cancel() {
      micromodal.close(this.id);
    },
  },
};
</script>
